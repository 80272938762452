import React from 'react'

import { Router } from "@reach/router"

import PageCasePreview from 'components/pageDataPreview/PageCasePreview'

const CasePreview = () => (
  <Router basepath={`/ru/case_preview`}>
    <PageCasePreview path={'/:slug'} lang={'ru'} />
  </Router>
)

export default CasePreview
