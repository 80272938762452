import React, { useEffect } from "react"

import { useStore } from "effector-react"

import cs from "state/caseStore"

import PageCaseDetail from "components/PageCaseDetail"
import Layout from "components/Layout/Layout"

const PageCasePreview = ({ slug }) => {
  const caseItem = useStore(cs.detail.store)
  const casesList = useStore(cs.list.store)

  useEffect(async () => {
    const { goal } = await cs.detail.fetchEffect(slug)

    await cs.list.fetchEffect({ goal__id__in: goal.id })
  }, [slug])

  if (!caseItem.id) return null

  return (
    <Layout color="white">
      <PageCaseDetail caseDetail={caseItem} casesList={casesList} />
    </Layout>
  )
}

export default PageCasePreview
