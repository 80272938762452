import { getCase } from '../api'

import casesStore from './casesStore'
import generator from './fabrics/single'

const caseFabric = generator({
  title: 'case',
  fetch: getCase,
  fieldsToTranslate: [
    'title',
    'titleShort',
    'content',
    'pdf',
    { key: 'goal', fieldsToTranslate: ['name'] },
    { key: 'brands', fieldsToTranslate: ['title'] },
  ],
})

export default {
  detail: caseFabric,
  list: casesStore,
}
